/**
 * Author: Robert Hernandez
 * Last Modified: July 7th, 2020
 * 
 * This file contains all URIs used by qcserver.
 */

// let STATIONS_URI_ = process.env.STATIONS_URI;

// Used for development
let AUTH_LOGIN_URI_ = 'https://localhost:8081/authentication/login';
let AUTH_LOGOUT_URI_ = 'https://localhost:8081/authentication/logout';
let AUTH_VERIFY_ = 'https://localhost:8081/authentication/verify';
let AUTH_USERS_ = 'https://localhost:8081/authentication/users';
let AUTH_CHANGE_PWD_ = 'https://localhost:8081/authentication/change_password';
let AUTH_SAVE_USER_ = 'https://localhost:8081/authentication/save_user';
let AUTH_SAVE_NEW_USER_ = 'https://localhost:8081/authentication/save_new_user';
let AUTH_REMOVE_USER_ = 'https://localhost:8081/authentication/remove_user';

let QCNOTE_URI_ = 'https://localhost:8082/qcnote/';
let LAST_NOTE_URI_ = 'https://localhost:8082/qcnote/last_note';
let UPDATE_NOTE_URI_ = 'https://localhost:8082/qcnote/update_note';

let QCNETSTAT_URI_ = 'https://localhost:8082/qcnetstat/access';
let NETSTAT_ISSUES_URI_ = 'https://localhost:8082/qcnetstat/get_issues';
let NETSTAT_PUBLISHED_URI_ =  'https://localhost:8082/qcnetstat/get_published';
let NETSTAT_SAVE_ISSUE_URI_ = 'https://localhost:8082/qcnetstat/save_issue'; 
let NETSTAT_REMOVE_ISSUE_ = 'https://localhost:8082/qcnetstat/remove_issue'; 

let HOME_URI_ = QCNOTE_URI_;
let STATIONS_URI_ =         'https://localhost:8082/idastatus_api/stations';

let DOMAIN = process.env.REACT_APP_DOMAIN;

if (process.env.REACT_APP_ENV === 'PROD') {
    /** PROD:    Using DOCKER with PROXY SERVER and SSL;    domain: ida-cq.ucsd.edu */
    
    AUTH_LOGIN_URI_ =           `https://${DOMAIN}/qcauth/authentication/login`;
    AUTH_LOGOUT_URI_ =          `https://${DOMAIN}/qcauth/authentication/logout`;
    AUTH_VERIFY_ =              `https://${DOMAIN}/qcauth/authentication/verify`;
    AUTH_USERS_ =               `https://${DOMAIN}/qcauth/authentication/users`;
    AUTH_CHANGE_PWD_ =          `https://${DOMAIN}/qcauth/authentication/change_password`;
    AUTH_SAVE_USER_ =           `https://${DOMAIN}/qcauth/authentication/save_user`;
    AUTH_SAVE_NEW_USER_ =       `https://${DOMAIN}/qcauth/authentication/save_new_user`;
    AUTH_REMOVE_USER_ =         `https://${DOMAIN}/qcauth/authentication/remove_user`;
    
    QCNOTE_URI_ =               `https://${DOMAIN}/qcserver/qcnote/`;
    LAST_NOTE_URI_ =            `https://${DOMAIN}/qcserver/qcnote/last_note`;
    UPDATE_NOTE_URI_ =          `https://${DOMAIN}/qcserver/qcnote/update_note`;  
    
    QCNETSTAT_URI_ =            `https://${DOMAIN}/qcserver/qcnetstat/access`;
    NETSTAT_ISSUES_URI_ =       `https://${DOMAIN}/qcserver/qcnetstat/get_issues`;  
    NETSTAT_PUBLISHED_URI_ =    `https://${DOMAIN}/qcserver/qcnetstat/get_published`;  
    NETSTAT_SAVE_ISSUE_URI_ =   `https://${DOMAIN}/qcserver/qcnetstat/save_issue`;  
    NETSTAT_REMOVE_ISSUE_ =     `https://${DOMAIN}/qcserver/qcnetstat/remove_issue`;  
    
    HOME_URI_ = QCNOTE_URI_;
    STATIONS_URI_ =             `https://${DOMAIN}/qcserver/idastatus_api/stations`;
}
else if (process.env.REACT_APP_ENV === 'STAGING') {
    /** STAGING: Using DOCKER with PROXY SERVER but NO SSL; domain: localhost:8080 */

    AUTH_LOGIN_URI_ =           `http://${DOMAIN}:8080/qcauth/authentication/login`;
    AUTH_LOGOUT_URI_ =          `http://${DOMAIN}:8080/qcauth/authentication/logout`;
    AUTH_VERIFY_ =              `http://${DOMAIN}:8080/qcauth/authentication/verify`;
    AUTH_USERS_ =               `http://${DOMAIN}:8080/qcauth/authentication/users`;
    AUTH_CHANGE_PWD_ =          `http://${DOMAIN}:8080/qcauth/authentication/change_password`;
    AUTH_SAVE_USER_ =           `http://${DOMAIN}:8080/qcauth/authentication/save_user`;
    AUTH_SAVE_NEW_USER_ =       `http://${DOMAIN}:8080/qcauth/authentication/save_new_user`;
    AUTH_REMOVE_USER_ =         `http://${DOMAIN}:8080/qcauth/authentication/remove_user`;

    QCNOTE_URI_ =               `http://${DOMAIN}:8080/qcserver/qcnote/`;
    LAST_NOTE_URI_ =            `http://${DOMAIN}:8080/qcserver/qcnote/last_note`;
    UPDATE_NOTE_URI_ =          `http://${DOMAIN}:8080/qcserver/qcnote/update_note`;

    QCNETSTAT_URI_ =            `http://${DOMAIN}:8080/qcserver/qcnetstat/access`;
    NETSTAT_ISSUES_URI_ =       `http://${DOMAIN}:8080/qcserver/qcnetstat/get_issues`;
    NETSTAT_PUBLISHED_URI_ =    `http://${DOMAIN}:8080/qcserver/qcnetstat/get_published`;
    NETSTAT_SAVE_ISSUE_URI_ =   `http://${DOMAIN}:8080/qcserver/qcnetstat/save_issue`;
    NETSTAT_REMOVE_ISSUE_ =     `http://${DOMAIN}:8080/qcserver/qcnetstat/remove_issue`;

    HOME_URI_ = QCNOTE_URI_;
    STATIONS_URI_ =             `http://${DOMAIN}:8080/qcserver/idastatus_api/stations`;
}
else if (process.env.REACT_APP_ENV === 'DEV_DOCKER') {
    /** DEVELOPMENT using DOCKER w/o PROXY SERVER and w/o SSL */

    AUTH_LOGIN_URI_ =        `http://${DOMAIN}:3001/authentication/login`;
    AUTH_LOGOUT_URI_ =       `http://${DOMAIN}:3001/authentication/logout`;
    AUTH_VERIFY_ =           `http://${DOMAIN}:3001/authentication/verify`;
    AUTH_USERS_ =            `http://${DOMAIN}:3001/authentication/users`;
    AUTH_CHANGE_PWD_ =       `http://${DOMAIN}:3001/authentication/change_password`;
    AUTH_SAVE_USER_ =        `http://${DOMAIN}:3001/authentication/save_user`;
    AUTH_SAVE_NEW_USER_ =    `http://${DOMAIN}:3001/authentication/save_new_user`;
    AUTH_REMOVE_USER_ =      `http://${DOMAIN}:3001/authentication/remove_user`;

    QCNOTE_URI_ =            `http://${DOMAIN}:3002/qcnote`;
    LAST_NOTE_URI_ =         `http://${DOMAIN}:3002/qcnote/last_note`;
    UPDATE_NOTE_URI_ =       `http://${DOMAIN}:3002/qcnote/update_note`;

    QCNETSTAT_URI_ =         `http://${DOMAIN}:3002/qcnetstat/access`;
    NETSTAT_ISSUES_URI_ =    `http://${DOMAIN}:3002/qcnetstat/get_issues`;
    NETSTAT_PUBLISHED_URI_ = `http://${DOMAIN}:3002/qcnetstat/get_published`;
    NETSTAT_SAVE_ISSUE_URI_ =`http://${DOMAIN}:3002/qcnetstat/save_issue`;
    NETSTAT_REMOVE_ISSUE_ =  `http://${DOMAIN}:3002/qcnetstat/remove_issue`;

    HOME_URI_ = QCNOTE_URI_;
    STATIONS_URI_ =          `http://${DOMAIN}:3002/idastatus_api/stations`;
}

export let AUTH_LOGIN_URI = AUTH_LOGIN_URI_;
export let AUTH_LOGOUT_URI = AUTH_LOGOUT_URI_;
export let AUTH_VERIFY = AUTH_VERIFY_;
export let AUTH_USERS = AUTH_USERS_;
export let AUTH_SAVE_USER = AUTH_SAVE_USER_;
export let AUTH_SAVE_NEW_USER = AUTH_SAVE_NEW_USER_;
export let AUTH_REMOVE_USER = AUTH_REMOVE_USER_;
export let AUTH_CHANGE_PWD = AUTH_CHANGE_PWD_;

export let QCNOTE_URI = QCNOTE_URI_;
export let LAST_NOTE_URI = LAST_NOTE_URI_;
export let UPDATE_NOTE_URI = UPDATE_NOTE_URI_;

export let QCNETSTAT_URI = QCNETSTAT_URI_;
export let NETSTAT_ISSUES_URI = NETSTAT_ISSUES_URI_;
export let NETSTAT_PUBLISHED_URI = NETSTAT_PUBLISHED_URI_;
export let NETSTAT_SAVE_ISSUE_URI = NETSTAT_SAVE_ISSUE_URI_;
export let NETSTAT_REMOVE_ISSUE = NETSTAT_REMOVE_ISSUE_;

export let HOME_URI = HOME_URI_;
export let STATIONS_URI = STATIONS_URI_;

