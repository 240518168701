import React from 'react';
import axios from 'axios';
import { AUTH_USERS, AUTH_SAVE_USER, AUTH_REMOVE_USER, AUTH_SAVE_NEW_USER, AUTH_CHANGE_PWD } from './../Constants/Const_URI';
import { ERR_SAVE_USER, ERR_GET_USERS, ERR_UNMATCH_PWD } from './../Constants/Const_ERR';
import { QCPAGES, QC_COLORS } from './../Constants/Constants';
import { FaRegTrashAlt, FaRegSave, FaPlusSquare } from 'react-icons/fa'; 
import { Grid, html } from 'gridjs';
import { _ } from 'gridjs-react';
import { Modal } from 'react-bootstrap';


class Account extends React.Component {
    constructor(props) {
        super(props);
        this.grid_ref= React.createRef();
        this.grid = null;
        this.state = {
            username: this.props.data.username,
            role: this.props.data.role,
            user_details_error: '',
            user_details_success: '',
            user_details_loading: false,
            user_control_error: '',
            user_control_success: '',
            user_control_loading: false,
            error: '',
            users: [],
            roles: {},
            role_desc: [],
            removeUserModal: {
                showModal: false,
                username: '',
                userInput: ''
            }
        };
    }

    /** 
     * Source is a cancel token that is passed to axios calls to cancel listening
     * for responses from the api. This is vital to prevent axios from continuing
     * to make component changes after the component has already been unmounted,
     * which would cause potential memory leaks.  
     */
    CancelToken = axios.CancelToken;
    source = this.CancelToken.source();

    componentDidMount() {
        if (!this.props.data || !this.props.data.username || !this.props.data.role) {
            this.props.history.push('/');
        }
        this.getUsers();
    }

    /**
     * Cancels any asynchronous calls before leaving page to prevent memory leaks.
     */
    componentWillUnmount() {
        this.grid = null;
        this.source.cancel();
    }     

    getUsers() {
        this.setState({ user_control_loading: true });
        return axios.post(AUTH_USERS, {  },
            { cancelToken: this.source.token, timeout: 3000, withCredentials: true })
            .then(res => {
                let res_data = Object.assign({ error: '' }, res.data)
                this.setState(res_data);
            })
            .then(() => {
                let columns = ['Username', 'Role'].concat(QCPAGES, ['-']);
                this.grid = Object.keys(this.state.roles).length > 1 && this.grid === null 
                        ? new Grid({columns: columns, data: []}).render(this.grid_ref.current)
                        : this.grid;
                this.generateUserTable();
                this.setState({ user_control_loading: false });
            })
            .catch(err => {
                if (axios.isCancel(err)) {
                    console.log('Request cancelled');
                }
                else {
                    let error = err.response ? err.response.data : null;
                    error = error ? error.error : ERR_GET_USERS;
                    let stateObj = { 
                        user_control_loading: false,
                        user_control_success: '',
                        user_control_error: error
                    };
                    this.setState(stateObj);
                    console.log(error);
                }
            });
    }

    addUser(e) {
        e.preventDefault();
        let form_id = e.target.id;
        this.setState({ user_control_loading: true });
        const new_username = e.target.username.value;
        const new_role = e.target['qc-select-user-role'].value;
        axios.post(AUTH_SAVE_NEW_USER, 
            { username: new_username, role: new_role },
            { cancelToken: this.source.token, timeout: 2500, withCredentials: true })
            .then(res => {
                this.getUsers();
                let stateObj = { 
                    user_control_loading: false,  
                    user_control_success: `Successfully added ${new_username}.`,
                    user_control_error: ''
                };
                this.setState(stateObj);
                document.getElementById(form_id).reset();
            })
            .catch(err => {
                if (axios.isCancel(err)) {
                    console.log('Request cancelled');
                }
                else {
                    let error = err.response ? err.response.data : null;
                    error = error ? error.error : ERR_SAVE_USER;
                    let stateObj = { 
                        user_control_loading: false,
                        user_control_success: '',
                        user_control_error: error
                    };
                    this.setState(stateObj);
                    console.log(error);
                }                
            });
    }

    removeUser(username) {
        this.setState({ user_control_loading: true, removeUserModal: {} });
        axios.post(AUTH_REMOVE_USER, 
            { username },
            { cancelToken: this.source.token, timeout: 2500, withCredentials: true })
            .then(res => {
                console.log('success');
                this.getUsers();
                let stateObj = { 
                    user_control_loading: false,  
                    user_control_success: `Successfully removed ${username}.`,
                    user_control_error: ''
                };
                this.setState(stateObj)
            })
            .catch(err => {
                if (axios.isCancel(err)) {
                    console.log('Request cancelled');
                }
                else {
                    let error = err.response ? err.response.data : null;
                    error = error ? error.error : ERR_SAVE_USER;
                    let stateObj = { 
                        user_control_loading: false,
                        user_control_success: '',
                        user_control_error: error
                    };
                    this.setState(stateObj);
                    console.log(error);
                }
            });
    }

    saveUser(username, e) {
        e.preventDefault();
        this.setState({ user_control_loading: true });
        const role_change = e.target['qc-select-user-role'].value;
        let write_access_change = {};
        for (let i = 0; i < QCPAGES.length; i++) {
            let checkbox_page_name = `qc-checkbox-${QCPAGES[i]}-user-write-access`; 
            write_access_change[QCPAGES[i]] = e.target[checkbox_page_name].checked;
        }
        axios.post(AUTH_SAVE_USER, 
            { username, role: role_change, write_access: write_access_change },
            { cancelToken: this.source.token, timeout: 2500, withCredentials: true })
            .then(res => {
                this.getUsers();
                let stateObj = { 
                    user_control_loading: false,  
                    user_control_success: `Successfully saved ${username}.`,
                    user_control_error: ''
                };
                this.setState(stateObj)
                console.log('success');
            })
            .catch(err => {
                if (axios.isCancel(err)) {
                    console.log('Request cancelled');
                }
                else {
                    let error = err.response ? err.response.data : null;
                    error = error ? error.error : ERR_SAVE_USER;
                    let stateObj = { 
                        user_control_loading: false,
                        user_control_success: '',
                        user_control_error: error
                    };
                    this.setState(stateObj);
                    console.log(error);
                }                
            });
    }

    changePassword(e) {
        e.preventDefault();
        this.setState({ user_details_loading: true });
        let form_id = e.target.id;
        const new_pass = e.target.password.value;
        const re_new_pass = e.target.repassword.value;
        if (new_pass === re_new_pass) {
            axios.post(AUTH_CHANGE_PWD, 
                { password: new_pass },
                { cancelToken: this.source.token, timeout: 2500, withCredentials: true })
                .then(res => {
                    document.getElementById(form_id).reset();
                    let stateObj = { 
                        user_details_loading: false,
                        user_details_error: '',
                        user_details_success: 'Successfully changed password.'
                    };
                    this.setState(stateObj);
                })
                .catch(err => {
                    if (axios.isCancel(err)) {
                        console.log('Request cancelled');
                    }
                    else {
                        let error = err.response ? err.response.data : null;
                        error = error ? error.error : ERR_SAVE_USER;
                        let stateObj = { 
                            user_details_loading: false,
                            user_details_error: error,
                            user_details_success: ''
                        };
                        this.setState(stateObj);
                        console.log(error);
                    }
                });
        }
        else {
            let stateObj = { 
                user_details_loading: false,
                user_details_error: ERR_UNMATCH_PWD,
                user_details_success: ''
            };
            this.setState(stateObj);
            console.log(ERR_UNMATCH_PWD);
        }
    }

    generateRemoveUserModal() {
        let username = this.state.removeUserModal.username;
        let showModal = this.state.removeUserModal.showModal;
        let userInput = this.state.removeUserModal.userInput;
        userInput = userInput ? userInput : '';

        let onHide = () => {
            this.setState({ removeUserModal: { showModal: false } })
        };
        let onInput = (e) => {  
            let newState = this.state.removeUserModal;
            newState['userInput'] = e.target.value;   
            this.setState({ removeUserModal: newState });
        };
        let onSubmit = (e) => {
            e.preventDefault();
            if (userInput === username) {
                this.removeUser(username);
            }
        } 
        
        let remove_user_btn = userInput === username ? (
            <button type="submit">
            I know what I'm doing. Delete user.
            </button>
            ) : (
            <button type="button" disabled>
            I know what I'm doing. Delete user.
            </button>
        );

        return (
            <Modal id="qc-remove-item-modal" animation={false} show={showModal} onHide={onHide} centered>
            <Modal.Header className="qc-remove-item-modal-header" closeButton>
            Delete user?
            </Modal.Header>
            <Modal.Body className="qc-flex-vertical">
            <p>
            This action <b>cannot</b> be undone.<br/>
            This will permanently delete <strong>{username}</strong>.
            <br/><br/>Type <strong>{username}</strong> below to confirm.
            </p>
            <form className="qc-confirm-remove-item qc-flex-vertical"
                onSubmit={onSubmit}>
            <input type="text" value={userInput} onChange={onInput}/>
            {remove_user_btn}
            </form>
            </Modal.Body>
            </Modal>
        );
    }

    generateCurrentUserDetails() {
        const roles = Object.keys(this.state.roles);
        const write_access = this.props.data.write_access;
        let page_access = Object.keys(write_access).filter(page => {
            return write_access[page];
        }).map(page => {
            return (<li key={`user_detail_key_${page}`}>{page}</li>);
        });

        let role_desc = this.state.role_desc.map((desc, idx) => {
            return (<li key={`${idx}_${desc.substr(0,5)}`}><em>{desc}</em></li>);
        });
        return (
            <section className="qc-account-content">
            <section>
                You have <strong>{roles[0]}</strong> access.<br/>
                <ul>{role_desc}</ul>
                {   page_access.length > 0 &&
                    (<div>You currently have write access to:<br/>
                    <ul>{page_access}</ul></div>)
                }
            </section>
            
            {   this.state.user_details_loading ? 
                 <p className="qc-loading-message">Loading...</p>   :
                (<div><p className="qc-error-message">{this.state.user_details_error}</p>
                <p className="qc-success-message">{this.state.user_details_success}</p></div>)
            }
            <form id="qc-account-user-detail-form" className="qc-flex-vertical" 
                    onSubmit={this.changePassword.bind(this)} >
            <input type="password" name="password" placeholder="Change Password" required/>
            <input type="password" name="repassword" placeholder="Re-enter Password" required/>
            <button type="submit" value="save">Change Password</button>
            </form>
            </section>

        );
    }

    generateAddUserForm() {
        let form_id = `qc-account-add-user-form`;
        const roles = this.state.roles;
        const role_names = Object.keys(roles);
        let options = [];
        for (let i = 1; i < role_names.length; i++) {
            let option_key = `qc-role-option-${role_names[i]}`
            options.push((<option key={option_key} value={roles[role_names[i]]}>{role_names[i]}</option>));
        }
        return (
            <form id={form_id} onSubmit={this.addUser.bind(this)} >
            <input type="text" name="username" placeholder="Enter username" />
            <select name="qc-select-user-role">
                {options}
            </select>
            <button type="submit" value="save"><FaPlusSquare id="qc-account-add-user-icon" /></button>
            </form>
        );
    }


    generateUserTable() {
        const roles = this.state.roles;
        const role_names = Object.keys(roles);
        if (role_names.length > 1 && this.grid) {
            let form_id;
            // Prepares select options under role column
            let roles_col = [{
                name: 'Role',
                formatter: (cell, row) => {
                    let options = '';
                    form_id = `qc-account-${row.cells[0].data}-form`;
                    for (let i = 1; i < role_names.length; i++) {
                        if (row.cells[1].data !== roles[role_names[i]]) {
                            options = options.concat(`<option value="${roles[role_names[i]]}">${role_names[i]}</option>`);
                        }
                        else {
                            options = options.concat(`<option value="${roles[role_names[i]]}" selected>${role_names[i]}</option>`)
                        }
                    }
                    return html(`<select name="qc-select-user-role" form="${form_id}">`.concat(options, '</select>'));
                }
            }];

            // Prepares checkboxes under qcpages column(s)
            let qcpagelist_cols = QCPAGES.map(page => {
                let checkbox_name = `qc-checkbox-${page}-user-write-access`;
                return { 
                        name: page,
                        formatter: (cell, row) => {
                            return cell ? html(`<input name="${checkbox_name}" type="checkbox" form="${form_id}" checked/>`)
                                        : html(`<input name="${checkbox_name}" type="checkbox" form="${form_id}" />`);
                        }
                }; 
            });

            // Prepares save and remove user buttons on form
            let qc_user_buttons = [{
                name: '',
                formatter: (cell, row) => {
                    let buttons = (
                        <form onLoad={this.addGridRowColor()} name={`qc_account_table_row_form_${row.cells[1].data}`} id={form_id} onSubmit={this.saveUser.bind(this, row.cells[0].data)}>
                        <button form={form_id} type="submit">
                        <FaRegSave id="qc-account-save-icon"/>
                        </button>
                        <button form={form_id} type="button" className="qc-account-grid-button" 
                                onClick={() => {
                                        let removeUserModal = { username: row.cells[0].data, showModal: true };
                                        this.setState({ removeUserModal });
                                    }}>
                        <FaRegTrashAlt id="qc-account-trash-icon" />
                        </button>
                        </form>
                    );
                    return _(buttons);
                }
            }];

            let columns = [{name: 'Username'}].concat(roles_col, qcpagelist_cols, qc_user_buttons);
            let data = this.state.users.map(user => {
                let role_name = role_names[user.role - this.state.role];
                let user_access = Array(QCPAGES.length).fill(false);
                for (let i = 0; i < user_access.length; i++) {
                    user_access[i] = user.write_access ? user.write_access[QCPAGES[i]] : false;
                }
                return [user.username, user.role].concat(user_access, [`${role_name}______`]);
            });
        
            this.grid.updateConfig({
                columns: columns,
                fixedHeader: true,
                data: data,
                search: true,
                sort: true
            }).forceRender();
        }
    }

    addGridRowColor() {
        const roles = this.state.roles;
        const role_names = Object.keys(roles);
        // Populates rows with classNames for styling per different role
        for (let i = 1; i < role_names.length; i++) {
            let role = roles[role_names[i]];
            let elem_name = `qc_account_table_row_form_${role}`
            let nodes = document.getElementsByName(elem_name);
            nodes.forEach(node => {
                let row_nodes = node.parentNode.parentNode.parentNode.childNodes;
                row_nodes.forEach(node_ => {
                    node_.setAttribute('style', `background-color:${QC_COLORS[role]};`);
                })
            });
        }
    }

    render() {
        const username = this.state.username;
        return (
            <div>
            <main id="qc-account-main" className="qc-flex-vertical">
                <h2>Welcome {username}</h2>
                {this.generateRemoveUserModal()}
                {this.generateCurrentUserDetails()}
                { Object.keys(this.state.roles).length > 1 ?
                    (<section id="qc-account-user-control" className="qc-account-content">
                        {this.state.user_control_loading ? 
                            <p className="qc-loading-message">Loading...</p>: 
                        (<div className="qc-status-field">
                        <p className="qc-error-message">{this.state.user_control_error}</p>
                        <p className="qc-success-message">{this.state.user_control_success}</p>
                        </div>)
                        }
                        <div id="qc-account-grid" ref={this.grid_ref}></div>
                        {this.generateAddUserForm()}
                        <p>
                        <span><i><strong>Note:</strong></i></span> 
                        <span><i>
                        Default password will be the same as the username. <br />
                        Password will be padded with zeros if length is less than 8 characters.
                        </i></span>
                        </p>
                    </section>) : null
                }
            </main>
            </div>
        );

    }
}

export default Account;