/**
 * Author: Robert Hernandez
 * Last Modified: June 19, 2020
 * 
 * QC Metrics Page
 * Organizes the percent availability, num gaps, max gap, and noise spectrograms
 * for each active station.
 */

import React from 'react';
import axios from 'axios';
import { STATIONS_URI } from '../../Constants/Const_URI';
import SideBar from './SideBar';
import MainContent from './StationContent/MainContent.js';
import { Row, Col, Tab } from 'react-bootstrap';


class QCMetrics extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            stations: [], // List of stations
            activeStation: "", // Current displayed station
            loading: true, // Set when component is still mounting
        }
    }

    /** 
     * Source is a cancel token that is passed to axios calls to cancel listening
     * for responses from the api. This is vital to prevent axios from continuing
     * to make component changes after the component has already been unmounted,
     * which would cause potential memory leaks.  
     */
    CancelToken = axios.CancelToken;
    source = this.CancelToken.source();

    /**
     *  Gets list of stations everytime the QC Metrics page is loaded, to ensure
     *  latest list of stations when page is accessed.
     */
    componentDidMount() {
        this.getStations();
    }

    /**
     * Cancels any asynchronous calls before leaving page to prevent memory leaks.
     */
    componentWillUnmount() {
        this.source.cancel();
    } 

    /**
     * Retrieves the list of stations from STATIONS_URI API
     * The state variables will either be empty or contain station(s) by the end
     * of this function call.
     */
    getStations() {
        axios.get(STATIONS_URI, { cancelToken: this.source.token, timeout: 2500 })
            .then(res => {
                let stationCodes = res.data.stationCodes;
                if (stationCodes.length) {
                    this.setState({ 
                        activeStation: stationCodes[0],
                        stations: stationCodes,
                        loading: false
                    });
                }
                else {
                    this.setState({ 
                        activeStation: '',
                        stations: [],
                        loading: false 
                    });
                }
            })
            .catch(error => {
                    if (axios.isCancel(error)) {
                        console.log('Request cancelled');
                    }
                    else {
                        console.log(error);
                        this.setState({ 
                            activeStation: '',
                            stations: [], 
                            loading: false
                        });
                    }
            });
    }

    render() {
        let stations = this.state.stations;
        let activeStation = this.state.activeStation;
        // If the list of stations could not be retrieved, the page will display
        //   the header only
        let body = activeStation ? (
                <Tab.Container defaultActiveKey={stations[0]}
                    onSelect={ station => {this.setState({ activeStation: station })}}>
                <Row id="qc-qcmetrics-body">
                    <Col id="qc-qcmetrics-sidebar-col">
                    <SideBar stations={stations} />
                    </Col>
                    <Col id="qc-qcmetrics-content-col">
                    <Tab.Content id="qc-qcmetrics-content">
                        <Tab.Pane eventKey={activeStation}>
                        <MainContent activeKey={activeStation} />
                        </Tab.Pane>
                    </Tab.Content>
                    </Col>
                </Row>
                </Tab.Container>
            ) : 
            (
                <main>
                    <p className="qc-main-content">
                    Either the list of stations could not be retrieved from&nbsp; 
                    <a href={STATIONS_URI}>{STATIONS_URI}</a> 
                    &nbsp;or something went wrong with the client.
                    </p>
                </main>
            )


        return this.state.loading 
        ? (<main className="qc-main-content">Loading...</main>)
        : (<div>{body}</div>);
    }
}

export default QCMetrics;
