/**
 * Author: Robert Hernandez
 * Last Modified: June 19, 2020
 * 
 * Displays the percent availability, num gaps, max gap, and noise spectrograms
 * for the given station.
 */

import React from 'react';
import Metric from './Metric.js';
import SpecMetric from './SpecMetric.js'
import NoteContent from './NoteContent.js';
import {Tab, Row, CardGroup} from 'react-bootstrap';

// The station selected must be passed as a prop to this custom tag 
class MainContent extends React.Component {
    // Creates the QC metrics for a specific station
    createMetrics(station) {
        // List of QC metrics
        const metrics = [
            "percent_availability", 
            "num_gaps", 
            "max_gap",
            "dc_offset", 
            "sample_mean"]; 
    
        // Creates an array of QC metrics 
        let metricDeck = metrics.map((metric, index)=> {
            return (
                <Metric key={station+metric+index} 
                        station={station} metric={metric} />
            );
        });

        return (
            <CardGroup id="qc-qcmetrics-card-deck">
                {metricDeck}
            </CardGroup>
        );
    }

    // Creates and formats the noise spectrogram of a specified station 
    createSpecMetrics(station) {
        /**
         * The Noise Spectrograms will have the following format:
         * 00.BH1   00.BH2   00.BHZ
         * 01.BH1   01.BH2   01.BHZ
         * 00.LH1   00.LH2   00.LHZ
         * 01.LH1   01.LH2   01.LHZ
         */
        const locs = ["00", "10"];
        const dirs = ["H1", "H2", "HZ"];
        const freqs = ["B", "L"];

        // Holds an array of CardGroups which contains a group of noise
        //   spectrograms
        let groupCardGroups = [];
        for (let i = 0; i < freqs.length; i++) {
            for (let j = 0; j < locs.length; j++) {
                // Stores an array of noise spectrograms of sensor:
                // Frequency: freqs[i]
                // Location: locs[j]
                // in all directions of dirs
                let groupCards = [];
                for (let k = 0; k < dirs.length; k++) {
                    const key = station + locs[j] + freqs[i] + dirs[k];
                    // Creates a noise spectrogram component
                    let specMet = (<SpecMetric key={key}
                        station={station} freq={freqs[i]} 
                        loc={locs[j]} dir={dirs[k]} />);
                    groupCards.push(specMet);
                }

                // Each array of groupCards will have be organized into a
                //   CardGroup within a Row
                groupCards = (
                    <Row className="qc-bootstrap-override" key={"R" + freqs[i] + locs[j]}>
                    <CardGroup key={"CG" + freqs[i] + locs[j]} >
                        {groupCards}
                    </CardGroup>
                    </Row>
                );
                groupCardGroups.push(groupCards);
            }
        }

        return groupCardGroups;
    }

    // Creates the note content of a specific station
    createNoteContent(station) {
        return (
        <div>
        <NoteContent station={station} />
        </div>
        );
    }


    render() {
        const currStation = this.props.activeKey;
        const metrics = this.createMetrics(currStation);
        const specMetrics = this.createSpecMetrics(currStation);
        const noteContent = this.createNoteContent(currStation);

        return (
            <Tab.Content>
                {noteContent}

                <h2>QC Metrics</h2>
                {metrics}

                <h2>Noise Spectrograms</h2>
                {specMetrics}
                
            </Tab.Content>
        );
    }
}

export default MainContent;