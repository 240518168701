/**
 * Author: Robert Hernandez
 * Last Modified: June 19, 2020
 * 
 * Constructs a Card of a Noise Spectrogram of a given station. 
 * 
 * Note: NS - Noise Spectrogram
 */
import React from 'react';
import {Card, Modal, Image} from 'react-bootstrap';

/** 
 * A station and the sensor's location, frequency, and direction must be passed
 * to the props of this custom tag
 */
class SpecMetric extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false, // Set when a modal of a NS is opened
            goodImg: true, // Set when the image of a NS is retrievable
            dir: this.props.dir //dir is used for an alternative direction
        };
    }

    /** Opens the noise spectrogram Modal */
    openLightBox() {
        if (this.state.goodImg)
            this.setState({isOpen: true});
    }

    /** Closes the noise spectrogram Modal */
    closeLightBox() {
        this.setState({isOpen: false});
    }

    /** Opens the noise spectrogram in a new tab */
    openImageNewTab(url) {
        window.open(url);
        window.focus();  
    }

    /** 
     * Sets the dir to another possible format; if that doesn't load the image,
     * the status of the image is set to false if the image fails to load again
     */
    imgLoadErr() {
        const dir = this.state.dir;
        switch (dir) {
            case 'H1': 
                this.setState({dir: 'HN'});
                break;
            case 'H2':
                this.setState({dir: 'HE'});
                break;
            default:
                this.onError = null;
                this.setState({goodImg: false}); 
        }
    }

    /** Returns the expected display of the noise spectrogram */
    displaySpecMetric() {
        // Constructs the url of where the image of a noise spectrogram is located
        const station = this.props.station;
        const dir = this.state.dir;
        const loc = this.props.loc;
        const freq = this.props.freq;

        const domain = 'https://ida.ucsd.edu/';
        const subd = 'DCC/noise-spectrograms/';
        const suffix = ".last365.png";
        const prefix = domain + subd + station.toLowerCase() + "/II." + station + ".";
        let url = `${prefix}${loc}.${freq}${dir}${suffix}`;

        // If true, Modal of noise spectrogram will be displayed; otherwise it will be hidden
        let isOpen = this.state.isOpen;
        
        return (
            <div>
            <Card className="qc-ns-metric-card">
            { // If the image fails to load, the noise spectrogram will be rerendered to
              //   not display this Card Image which has the ability to turn on the modal
                this.state.goodImg &&
                <Card.Img variant="top" src={url}
                    alt={station + loc + freq + dir}
                    onClick={this.openLightBox.bind(this, url)}
                    onError={this.imgLoadErr.bind(this)} />
            }
            { // Simple message displayed when the image of a noise spectrogram could not be retrieved
                !this.state.goodImg &&
                <Card.Text>
                    No Data Available
                </Card.Text>
            }
            </Card>

            {/** Can never be open for a bad image */}
            {/** Currently animation causes warnings, so it is disabled 06/19/20
             * https://github.com/react-bootstrap/react-bootstrap/issues/5075
             */}
            <Modal show={isOpen} onHide={this.closeLightBox.bind(this)} 
                centered={true} animation={false} >
                <Image className="qc-metric-modal" src={url} 
                    alt={station + loc + freq + dir}
                    onClick={this.openImageNewTab.bind(this, url)}
                    onError={this.imgLoadErr.bind(this)} />
            </Modal>
            
            </div>
        );
    }

    render() {
        return this.displaySpecMetric();
    }
}

export default SpecMetric;