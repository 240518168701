/**
 * Author: Robert Hernandez
 * Last Modified: June 19, 2020
 * 
 * Constructs a Card of a given metric of a given station. 
 */

import React from 'react';
import {Card, Modal, Image} from 'react-bootstrap';

/** A station and metric must be passed as props to this custom tag */
class Metric extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false, // Set when a modal of a metric is opened
            goodImg: true // Set when the image of a metric is retrievable
        };
    }

    /** Opens the metric Modal */
    openLightBox() {
        this.setState({isOpen: true});
    }

    /** Closes the metric Modal */
    closeLightBox() {
        this.setState({isOpen: false});
    }

    /** Opens the metric in a new tab */
    openImageNewTab(url) {
        window.open(url);
        window.focus();  
    }

    /** 
     * Offsets the date by DAYS_UPDATED if necessary
     * Used to retrieve metrics two months back in time if the metric for the
     * previous month has not yet been updated
     */  
    setDate(dateObj) {
        // Day of the month of when a metric's data for the previous month 
        //   is created
        const DAY_UPDATED = 6
        if (dateObj.getDate() < DAY_UPDATED) {
            dateObj.setDate(dateObj.getDate() - DAY_UPDATED)
        }
    }

    /** Gets the month and year of the most recent updated metric */
    getDate() {
        const DECEMBER = 12;
        
        let date = new Date();
        this.setDate(date)
        let month = date.getMonth();
        let year = date.getFullYear();

        if (month < 1) {
            month = DECEMBER;
            year--;
        }

        return `_${month}-${year}`;
    }

    /** Sets the status of the image to false if the image fails to load */
    imgLoadErr() {
        this.setState({goodImg: false});
        this.onError = null;
    }

    /** Returns the metric to be displayed */
    displayMetric(metric) {
        // Constructs the url of where the image of a metric is located
        const domain = 'https://ida.ucsd.edu/';
        const subd = 'DCC/';
        const station = this.props.station;
        let date = this.getDate();
        const url = domain + subd + metric + "/II." + station + "_" + metric + date + ".png";

        // If true, Modal of metric will be displayed; otherwise it will be hidden
        let isOpen = this.state.isOpen;

        let metricJSX = (
            <div className="qc-bootstrap-override">
            <Card className="qc-metric-card">
            
            { // If the image fails to load, the metric will be rerendered to
              //   not display this Card Image which has the ability to turn on the modal
                this.state.goodImg &&
                <Card.Img variant="top" src={url}
                alt={metric} onError={this.imgLoadErr.bind(this)}
                onClick={this.openLightBox.bind(this, url)} />
            }
            { // Simple message displayed when the image of a metric could not be retrieved
                !this.state.goodImg &&
                <Card.Body>
                    No Data Available
                </Card.Body>
            }
            </Card>

            {/** Can never be open for a bad image */}
            {/** Currently animation causes warnings, so it is disabled 06/19/20
             * https://github.com/react-bootstrap/react-bootstrap/issues/5075
             */}
            <Modal show={isOpen} onHide={this.closeLightBox.bind(this)}
                    animation={false}>
                <Image className="qc-metric-modal" src={url} alt={metric}
                    onClick={this.openImageNewTab.bind(this, url)} />
            </Modal>
            
            </div>
        );

        return metricJSX; 
    }

    render() {
        let metric = this.props.metric;
        return this.displayMetric(metric);
    }
}

export default Metric;