/**
 * Author: Robert Hernandez
 * Last Modified: June 17, 2020
 * 
 * Function to check whether a user is authorized to access a webpage or not.
 */

import React from 'react';
// import { Route, Navigate } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import { ERR_SERVER } from '../../Constants/Const_ERR';

/**
 * Reroutes the web app to the page in ProtectedComponent if the user has
 * the appropriate credentials to access the page; if not, the page is redirected
 * back to the login page for credentials.
 * 
 * @param {Object} ProtectedComponent QC web page to display 
 * @param {String} accessURI URI used to gain access to the ProtectedComponent's 
 *                              resources.
 */
function checkAuth(ProtectedComponent, accessURI) {
    console.log('checkauth');
    return class extends React.Component {
        constructor() {
            super();
            this.state = {
                loading: true, // Set to false, for when accessURI has responded
                redirect: false, // Set to true for redirection to Login page 
                error: '', // Error message to display
                data: {}
            };
        }

        /** 
         * Source is a cancel token that is passed to axios calls to cancel listening
         * for responses from the api. This is vital to prevent axios from continuing
         * to make component changes after the component has already been unmounted,
         * which would cause potential memory leaks.  
         */
        CancelToken = axios.CancelToken;
        source = this.CancelToken.source();

        /**
         * Cancels any asynchronous calls before leaving page to prevent memory leaks.
         */
        componentWillUnmount() {
            this.source.cancel();
        } 

        componentDidMount() {
            // If a valid response is sent by the accessURI, the browser will
            //   stop loading and redirect to the ProtectedComponent; otherwise,
            //   the error message is appeneded as a prop to the Login page
            //   to be displayed.
            axios.post(accessURI, {}, 
                { cancelToken: this.source.token, timeout: 5000, withCredentials: true })
                .then(res => {
                    this.setState({ loading: false, error: '', data: res.data });
                })
                .catch( err => {
                    let resErr = err.response ? err.response.data.error : false;
                    let error = resErr ? resErr : ERR_SERVER
                    this.setState({ error, redirect: true });
                });
        }

        render() {
            let { loading, error, redirect } = this.state;
            let page;

            if (redirect) {
                page = (<Redirect to={{ pathname: '/login', state: { error: error} }}/>);
                // page = (<Route path="*" element={<Navigate to="/login" />} />);
            }
            else if (loading) {
                page = (
                    <div className="qc-login-frame qc-login-flex-vertical qc-loading-frame"> 
                        <h1>Loading...</h1>
                    </div>
                    );
            }
            else {
                // Necessary for default redirection when logged in
                page = (typeof ProtectedComponent === 'function') ?
                    (
                    <div>
                    <ProtectedComponent key={JSON.stringify(this.state.data)} {...this.props} data={this.state.data} />
                    </div>
                    ) :
                    ProtectedComponent;
            }

            return page;
        }
    }
}

export default checkAuth;