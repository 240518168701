/**
 * Author: Robert Hernandez
 * Last Modified: June 29, 2020
 * 
 * Note content component. Retrieves the most recent note made by a user.
 */

import React from 'react';
import {Toast, Form, Button, Row, Col, ButtonGroup} from 'react-bootstrap';
import { LAST_NOTE_URI, UPDATE_NOTE_URI } from '../../../Constants/Const_URI';
import { ERR_SERVER, ERR_NO_NOTE } from '../../../Constants/Const_ERR';
import axios from 'axios';

// A station must be passed as props to this custom tag
class NoteContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            qcmetrics: false, // Set when user has access to qcnotes
            loading: false, // Set when fetching note data from database
            error: '', // Error message received from the database
            username: '', // Username of the user who wrote the last note
            note: '',   // Will hold the most recent updated note or whatever is inputted
                        // onto the textbox
            show: false, // Toast message is displayed on error
            date: "", // The date of the most recent updated note
            timeStampBG: "#fbd1d1" // Color of timestamp background: default set to light red
        };
    }

    /** 
     * Source is a cancel token that is passed to axios calls to cancel listening
     * for responses from the api. This is vital to prevent axios from continuing
     * to make component changes after the component has already been unmounted,
     * which would cause potential memory leaks.  
     */
    CancelToken = axios.CancelToken;
    source = this.CancelToken.source();

    componentDidMount() {
        this.setState({ loading: true });
        this.getLastNote(this.props.station);
    }

    /**
     * Cancels any asynchronous calls before leaving page to prevent memory leaks.
     */
    componentWillUnmount() {
        this.source.cancel();
    } 

    // Populates the state variables that are dependent on the props whenever
    //   the props changes: changing station tabs
    componentDidUpdate(prevProps) {
        // Condition to prevent infinite loop
        // This method is called whenever a prop or state changes
        if (this.props !== prevProps) {
            let station = this.props.station;
            if (station !== "") {
                this.getLastNote(station);
            }
        }
    }

    // Retrieves the most recent note from the database
    getLastNote(station) { 
        axios.post(LAST_NOTE_URI, { station: station }, { cancelToken: this.source.token,
                timeout: 3000, withCredentials: true })
        .then(res => {
            res.data['loading'] = false;
            this.setState(res.data);
        })
        .catch(err => {
            let error = err.response ? err.response.data.error : ERR_SERVER;
            if (axios.isCancel(err)) {
                console.log('Request cancelled');
            }
            else {
                this.setState({ error, loading: false, show: true });
                console.log(error);
            }
        });
    }

    // Sets the current text within the textbox to be an empty string
    clearText() {
        this.setState({note: ""});
    }

    // Sends a new note to the server for it to be stored into a database
    // Also retrieves the updated note variables: noteID, note, noteDate
    updateNote(e) {
        this.setState({ loading: true });
        let dataObj = {};
        e.preventDefault();
        // Note object to send
        dataObj = {
            station: this.props.station,
            note: e.target.note.value,
            date: this.state.date,
        }
        axios.post(UPDATE_NOTE_URI, dataObj, { timeout: 3000, withCredentials: true })
        .then(res => {
            let resData = res.data;
            resData['loading'] = false;
            this.setState(resData);
        })
        .catch(err => {
            let error = err.response ? err.response.data : null;
            error = error ? error.error : ERR_SERVER;
            this.setState({ error, loading: false, show: true });
            console.log(error);
        })
    }

    // Updates the string in the textbox to be whatever the user inputs
    handleTextChange(e) {
        this.setState({note: e.target.value});
    }

    // Sets the text in the textbox to be the last updated note
    refreshNote() {
        this.setState({ loading: true });
        this.getLastNote(this.props.station);
    }

    // returns a string date format of when the note was last updated
    timeStamp() {
        const noteDate = this.state.date;

        // If date is not defined; noteDate will hold a string indicating
        //   that no note exists
        if (!noteDate || noteDate === ERR_NO_NOTE) {
            return ERR_NO_NOTE;
        }

        const moment = require('moment');
        const momentFormat = "YYYY-MM-DD / DDDD ddd";
        const noteMoment = moment(noteDate).format(momentFormat);
        return noteMoment;
    }



    // Determines whether a note is recent or not
    isRecent() {
        let noteDate = this.state.date;
        // If no note exists, it is not recent
        if (!noteDate || noteDate === ERR_NO_NOTE) {
            return false;
        }

        const moment = require('moment');
        const noteMoment = moment(noteDate);
        const currMoment = moment();
        const daysElapsed= currMoment.diff(noteMoment, 'days')
        // The number of days that must have elapsed to be considered not recent
        const MAX_DAYS = 30;
        return daysElapsed < MAX_DAYS;
    }

    // Determines the background color of the timestamp
    timeStampColor() {
        let color;
        if (this.state.loading) {
            color = '#F0FFFF';
        }
        else if (this.isRecent()) {
            color = 'lightcyan';
        }
        else {
            color = '#fbd1d1';
        }

        return color;
    }

    render() {
        let loading = this.state.loading;
        let timestamp = this.state.loading ? 'Retrieving note...' : `Last Note: ${this.timeStamp()}`;
        let last_user = this.state.username ? (<i id="qc-note-username">- {this.state.username}</i>) : null;
        let noteContainer = (
            <div>
            {/** Toast message */} 
            <Toast className="qc-toasts qc-top-toast"  animation={false}
            onClose={() => this.setState({show: false})}
            show={this.state.show} delay={3000} autohide>
            <Toast.Body>{this.state.error}</Toast.Body>
            </Toast>

            {/** Timestamp */}
            <div id="qc-qcmetrics-timestamp" style={{backgroundColor: this.timeStampColor()}}>
            {timestamp} {last_user}
            </div>


            {/** Form */} 
            <Form action="" 
            method="post" onSubmit={this.updateNote.bind(this)} >

            <Row id="qc-note-box-row" >
            {/** Textbox */}
            <Col>
            <Form.Group>
            { this.state.qcmetrics ?
            (<Form.Control as="textarea" name="note" rows="4" id="qc-qcmetrics-note-box"
                value={this.state.note} onChange={this.handleTextChange.bind(this)} />) :
            (<Form.Control as="textarea" name="note" rows="4" id="qc-qcmetrics-note-box"
            value={this.state.note} disabled/>)
            }
            </Form.Group>
            </Col>

            {/** Side buttons of textbox */}
            { this.state.qcmetrics ? (
                <Col sm="auto">
                <ButtonGroup vertical className="qc-note-buttons">
                <Button variant="none" className="qc-note-btn qc-note-update-btn" type="submit" 
                disabled={loading}>
                {loading ? '. . .' : 'Update'}
                </Button>
                <Button className="qc-note-clear-btn qc-note-btn" 
                onClick={this.clearText.bind(this)} disabled={loading}>
                {loading ? 'Loading' : 'Clear'}
                </Button>
                <Button variant="none" className="qc-note-refresh-btn qc-note-btn"  
                onClick={this.refreshNote.bind(this)} disabled={loading}>
                {loading ? ' . . . ' : 'Refresh'}
                </Button>
                </ButtonGroup>
                </Col>) : null 
            }
            </Row>
            </Form>
            </div>
        );
        return noteContainer;
    }
}

export default NoteContent;
