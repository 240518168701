/**
 * Author: Robert Hernandez
 * Last Modified: June 19, 2020
 * 
 * QC Metrics Page
 * Organizes the percent availability, num gaps, max gap, and noise spectrograms
 * for each active station.
 */

import React from 'react';
import axios from 'axios';
import { QCNETSTAT_URI, NETSTAT_REMOVE_ISSUE, STATIONS_URI, NETSTAT_ISSUES_URI, NETSTAT_SAVE_ISSUE_URI } from '../../Constants/Const_URI';
import { ERR_GET_ISSUES, ERR_STATIONS, ERR_SAVE_ISSUE, INV_CATEGORY } from '../../Constants/Const_ERR';
import { Modal, Badge, Form, Row, Col, Button, ButtonGroup, InputGroup } from 'react-bootstrap';
import { Grid, html } from "gridjs";
import { _ } from "gridjs-react";
import { CSVLink } from "react-csv";
import { FaFileCsv, FaPlusSquare } from "react-icons/fa";

class IssueManager extends React.Component {
    constructor(props) {
        super(props);
        this.grid_ref= React.createRef();
        this.grid = null;
        this.state = {
            stations: [],
            qcnetstatissues: false,
            defaultNet: '',
            JiraURI: '',
            categories: [],
            statuses: [],
            issueHeaders: [],
            issues: [],
            loading: false,
            error: '',
            showIssueEditor: false,
            removeIssueModal: {
                showModal: false,
                modifiedOn: '',
                issueID: '',
                issueCode: '',
                userInput: ''
            },
            gridData: [],
            filteredData: [],
            filterQuery: ''
        }
    }

    /** 
     * Source is a cancel token that is passed to axios calls to cancel listening
     * for responses from the api. This is vital to prevent axios from continuing
     * to make component changes after the component has already been unmounted,
     * which would cause potential memory leaks.  
     */
    CancelToken = axios.CancelToken;
    source = this.CancelToken.source();

    /**
     * Cancels any asynchronous calls before leaving page to prevent memory leaks.
     */
    componentWillUnmount() {
        this.grid = null;
        this.source.cancel();
    } 

    componentDidMount() {
        this.NetStatSetup();
    }

    async NetStatSetup() {
        this.setState({ loading: true, error: '' });
        await this.getStations();
        await this.getNetStatConstants();
        await this.getIssues();
        this.generateFilterBar();
        this.setState({ loading: false });
    }

    getNetStatConstants() {
        return axios.post(QCNETSTAT_URI, { }, 
            { cancelToken: this.source.token, timeout: 2500, withCredentials: true })
            .then(res => {
                this.setState({
                    defaultNet: res.data.def_net,
                    categories: res.data.categories,
                    statuses: res.data.statuses,
                    issueHeaders: res.data.issueHeaders,
                    JiraURI: res.data.JiraURI,
                    qcnetstatissues: res.data.qcnetstatissues
                });
            })
            .catch(error => {
                if (axios.isCancel(error)) {
                    console.log('Request cancelled');
                }
                else {
                    console.log(error);
                }
            });
    }

    /**
     * Retrieves the list of stations from STATIONS_URI API
     * The state variables will either be empty or contain station(s) by the end
     * of this function call.
     */
    getStations() {
        return axios.get(STATIONS_URI, { cancelToken: this.source.token, timeout: 2500 })
            .then(res => {
                let stationCodes = res.data.stationCodes;
                if (stationCodes.length) {
                    this.setState({ 
                        stations: stationCodes
                    });
                }
                else {
                    this.setState({ 
                        stations: []
                    });
                }
            })
            .catch(error => {
                    if (axios.isCancel(error)) {
                        console.log('Request cancelled');
                    }
                    else {
                        console.log(error);
                        this.setState({ 
                            stations: [],
                            error: ERR_STATIONS
                        });
                    }
            });
    }

    getIssues() {
        return axios.post(NETSTAT_ISSUES_URI, {  },
            { cancelToken: this.source.token, timeout: 3000, withCredentials: true })
            .then(res => {
                let issues = res.data.issues;
                let qcnetstatissues = res.data.qcnetstatissues;
                this.setState({ issues, qcnetstatissues });
                return issues;
            })
            .then((issues) => {
                let columns = this.state.issueHeaders;
                this.grid = columns.length > 0 && this.grid === null 
                        ? new Grid({columns: columns, data: []}).render(this.grid_ref.current)
                        : this.grid;
                return;
            })
            .then(() => {
                this.generateIssueTable();
            })
            .catch(err => {
                let error = err.response ? err.response.data : null;
                error = error ? error.error : err.message;
                error = error ? error : ERR_GET_ISSUES;
                if (axios.isCancel(err)) {
                    console.log('Request cancelled');
                }
                else if (error === 'INV_TOKEN') {
                    window.location.reload();
                }
                else {
                    let stateObj = { 
                        error: error
                    };
                    this.setState(stateObj);
                    console.log(error);
                }
            });
    }

    addGridRowEventListener(id, row) {
        let checkcell = document.getElementById(id);
        if (checkcell) {
            checkcell = checkcell.parentNode.parentNode.parentNode;
            checkcell.addEventListener('click', this.populateIssueEditor.bind(this, row));
            
            // Checks if data is published
            if ( row.cells[0].data[1].published ) {
                let child_nodes = checkcell.childNodes;
                let child;
                for (child of child_nodes) {
                    child.className += ' qc-IE-grid-published-row ';
                }
            }
        }
    }

    generateIssueTable() {
        let headerNames = this.state.issueHeaders.slice(1);
        const FORMAT_COL_IDX = 3;
        let non_formatted_cols = headerNames.slice(1, FORMAT_COL_IDX);
        let network_col = [{
            name: headerNames[0],
            formatter: (cell, row) => {
                return cell[0]
            }
        }];
        let description_col = [{
            name: headerNames[FORMAT_COL_IDX],
            formatter: (cell, row) => {
                let id = row._id;
                const charLength = 130;
                let description = cell.substr(0, charLength);
                description = cell.length > charLength ? description + '...' : description;
                return _(<p id={id} onLoad={this.addGridRowEventListener(id, row)} className="qc-IE-grid-desc">{description}</p>);
            }
        }];
        let categories_col = [{
            name: headerNames[FORMAT_COL_IDX + 1],
            sort: {
                compare: (a,b) => {
                    const SEP_CHAR = ','
                    let array_a = a.abbrs.split(SEP_CHAR).sort();
                    let array_b = b.abbrs.split(SEP_CHAR).sort();
                    return array_a.join(SEP_CHAR) < array_b.join(SEP_CHAR);
                }
            },
            formatter: (cell, row) => {
                let cell_abbrs = cell.abbrs.split(',');
                let html_abbrs = [];
                cell_abbrs.forEach((category) => {
                    html_abbrs.push((
                        <Badge key={`category${row._id}${category}`} className="qc-IE-categories" pill bg="primary">
                            {category}
                        </Badge>
                    ));
                });
                return _(html_abbrs); 
            }
        }];
        let jira_ticket_col = [{
            name: headerNames[FORMAT_COL_IDX + 2],
            formatter: (cell) => {
                let html_tics = '';
                if (cell.urls.length > 0) {
                    let cell_urls = cell.urls.split(',');
                    let cell_texts = cell.texts.split(',');


                    for(let i = 0; i < cell_urls.length; i++) {
                        html_tics += 
                        `<a target="_blank" rel="noopener noreferrer" href="${cell_urls[i]}">
                        (${cell_texts[i]})
                        </a>`;
                    }
                }
                return html(html_tics);
            }
        }];

        // createdOn is last header column
        let columns = network_col.concat(non_formatted_cols, description_col, categories_col, jira_ticket_col, headerNames[headerNames.length - 1]);

        let data = this.state.issues.map((issue) => {
            // Formats categories to one string separated by ',' for the categories and abbreviations
            let categories_data = { categories: '', abbrs: '' };
            issue.categories.forEach((category, index) => {
                if (index > 0) {
                    categories_data.categories += ','; 
                    categories_data.abbrs += ',';
                }
                categories_data.categories += `${category.category}`;
                categories_data.abbrs += `${category.abbr}`;
            });

            // Formats Jira Tickets to one string separated by ',' for the Jira urls and texts
            let Jira_data = { urls: '', texts: '' };
            issue.JiraTickets.forEach((tic, index) => {
                if (index > 0) {
                    Jira_data.urls += ','; 
                    Jira_data.texts += ',';
                }
                Jira_data.urls += `${tic.url}`;
                Jira_data.texts += `${tic.text}`;
            });

            return [ 
                [issue.network, 
                    { 
                        published: issue.published,
                        _id: issue._id,
                        modifiedOn: issue.modifiedOn,
                        modifiedBy: issue.modifiedBy,
                        createdOn: issue.createdOn,
                        createdBy: issue.createdBy    
                    }
                ], 
                issue.station, issue.status, issue.description,
                categories_data, Jira_data, issue.createdOn
            ]
        });
        this.setState({ gridData: data, gridCols: columns });
        this.setState({ filteredData: data });
        this.grid.updateConfig({
            columns: columns,
            fixedHeader: true,
            data: data,
            sort: true,
            style: {
                th: {
                    'width': '300px'
                },
                td: {
                    border: '1px solid #595959'
                }
            }
        }).forceRender();
    }

    filterData(datas) {
        const FILTER_CODES = ['n', 'sta', 'stat', 'c', 'p'];
        const NEXT_CODE_CHAR = '&';
        const FIND_CHAR = ':';
        const SEP_CHAR = ',';
        let query = this.state.filterQuery.toLowerCase().split(NEXT_CODE_CHAR);
        let new_datas = datas;
        let pub = false;
        while (query.length) {
            let endIdx = query[0].indexOf(FIND_CHAR);
            if (endIdx >= 0) {
                let code = query[0].slice(0, endIdx).trim();
                let items = query[0].slice(endIdx + 1).split(SEP_CHAR).map(item => item.trim());
                switch (code) {
                    case FILTER_CODES[0]:
                        new_datas = new_datas.filter((data) => {
                            for (let i = 0; i < items.length; i++) {
                                if (data[0][0].toLowerCase().indexOf(items[i]) >= 0) {
                                    return true;
                                }
                            }
                            return false;
                            //return items.indexOf(data[1].toLowerCase()) >= 0;
                        })
                        break;
                    case FILTER_CODES[1]:
                        new_datas = new_datas.filter((data) => {
                            for (let i = 0; i < items.length; i++) {
                                if (data[1].toLowerCase().indexOf(items[i]) >= 0) {
                                    return true;
                                }
                            }
                            //return items.indexOf(data[2].toLowerCase()) >= 0;
                            return false;
                        })
                        break;    
                    case FILTER_CODES[2]:
                        new_datas = new_datas.filter((data) => {
                            for (let i = 0; i < items.length; i++) {
                                if (data[2].toLowerCase().indexOf(items[i]) >= 0) {
                                    return true;
                                }
                            }
                            return false;
                            //return items.indexOf(data[2].toLowerCase()) >= 0;
                        })
                        break;
                    case FILTER_CODES[3]:
                        new_datas = new_datas.filter((data) => {
                            let abbr_categories = data[4].abbrs.toLowerCase();
                            for (let i = 0; i < items.length; i++) {
                                if (abbr_categories.indexOf(items[i]) >= 0) {
                                    return true;
                                }
                            }
                            return false;
                        })
                        break;  
                    case FILTER_CODES[4]:
                        let arg = items[0].toLowerCase();
                        pub = arg === 'true';
                        new_datas = new_datas.filter((data) => {
                            if (arg !== 'true' && arg !== 'false') {
                                return true;
                            }

                            return (data[0][1].published && arg === 'true')
                                || (!data[0][1].published && arg === 'false');
                        })
                        break;  
                   default:                                 
                }
            }
            else {
                new_datas = new_datas.filter(data => {
                    return data[3].toLowerCase().indexOf(query[0].trim()) >= 0;
                });
            }
            query.splice(0,1);
        }

        let pub_btn = document.getElementById('qc-IE-grid-filter-pub');
        if (pub) {
            pub_btn.style.backgroundColor = "#ccebff";
        }
        else {
            pub_btn.style.backgroundColor = "transparent";
        }

        if (this.grid && new_datas) {
            this.setState({ filteredData: new_datas});
            this.grid.updateConfig({
                data: new_datas
            }).forceRender();
        }
    }

    filterPublished() {
        const NEXT_CODE_CHAR = '&';
        const FIND_CHAR = ':';
        let new_filter_query = this.state.filterQuery.toLowerCase();
        let queries = new_filter_query.split(NEXT_CODE_CHAR);
        let old_queries = queries.slice(0);
        let pub = '';
        if (old_queries.length === 1 && old_queries[0] === '') {
            old_queries = [];
        }
        while (queries.length && !pub) {
            let last_idx = queries.length - 1;
            let code_idx = queries[last_idx].indexOf(FIND_CHAR);
            if (code_idx >= 0) {
                let code = queries[last_idx].slice(0, code_idx).trim();
                if (code ==='p') {
                    pub = queries[last_idx].slice(code_idx + 1).trim();
                    pub = pub ? pub : 'false';
                    break;
                }
            }
            queries.pop();
        }

        // User did not mention the 'publish' code in the query
        if (!pub) {
            old_queries.push(' p: true ');
        }
        // User has the 'publish' code set to true; Removes code.
        else if (pub === 'true') {
            let idx = queries.length - 1;
            old_queries.splice(idx, 1);
        }
        // User has unknown query with 'publish' code; toggles to true
        else {
            let idx = queries.length - 1;
            old_queries[idx] = ' p: true ';
        }
        new_filter_query = old_queries.join(' ' + NEXT_CODE_CHAR);
        this.setState({ filterQuery: new_filter_query });
        document.getElementById('qc-IE-grid-filter-input').value = new_filter_query;
        this.filterData(this.state.gridData);
    }

    generateFilterBar() {
        let wrapper_node = document.getElementById('qc-IE-grid');
        if (wrapper_node) { // && wrapper_node.childNodes.length === 1) { WHY DO WE CARE HOW MANY CHILDREN?
            let filter_node = document.createElement('div');
            filter_node.id = 'qc-IE-grid-bar';
        
            let filter_published_node = document.createElement('button');
            filter_published_node.innerHTML = 'PUBLISHED';
            filter_published_node.id = "qc-IE-grid-filter-pub";
            filter_published_node.addEventListener('click', (e) => {
                this.filterPublished();
            })

            let filter_column_node = document.createElement('input');
            filter_column_node.type = 'text'
            filter_column_node.id = 'qc-IE-grid-filter-input';
            filter_column_node.addEventListener('keyup', (e) => {
                e.preventDefault();
                this.setState({ filterQuery: e.target.value });
                filter_column_node.value = this.state.filterQuery;
                this.filterData(this.state.gridData);
            })

            filter_node.appendChild(filter_published_node);
            filter_node.appendChild(filter_column_node);
            wrapper_node.insertBefore(filter_node, wrapper_node.childNodes[0]);
        }
    }

    generateCSVDownload() {
        const FILENAME = 'Net_Stat_Issues_Data.csv';
        let headers = ['_id'].concat(this.state.issueHeaders);
        let csvData = [headers];
        if (this.state.gridData.length) {
            let filteredData = this.state.filteredData.map((data) => {
                const STAT_IDX = 1;
                const CAT_IDX = 4;
                return [ data[0][1]._id, data[0][1].published]
                        .concat([data[0][0]], 
                                data.slice(STAT_IDX, CAT_IDX),
                                [data[CAT_IDX].categories, data[CAT_IDX + 1].texts],
                                data[CAT_IDX + 2]
                                );
            });
            csvData = csvData.concat(filteredData);
        }

        return (
            <CSVLink id="qc-ns-csvlink" filename={FILENAME} data={csvData}><FaFileCsv id="qc-ns-csv-icon" /></CSVLink>
        );
    }


    generateIssueEditor() {
        let loading = this.state.loading;
        let editable = this.state.qcnetstatissues;
        // Stations datalist setup
        let stationlist_options = [];
        this.state.stations.forEach((station) => {
            // stationlist_options.push((
            //     <option key={`qc-IE-staionlist-option-${station}`}>{station}</option>
            // ));
            stationlist_options.push((
                <option key={`qc-IE-staionlist-option-${station}`}>{station}</option>
            ));
        });

        // Statuses datalist setup
        let statuslist_options = [];
        this.state.statuses.forEach((status) => {
            statuslist_options.push((
                <option key={`qc-IE-statuslist-option-${status}`}>{status}</option>
            ));
        });

        // Categories tags setup
        let category_tags = [];
        this.state.categories.forEach((categoryObj, ndx) => {
            let category = categoryObj.category;
            let id = `qc-IE-category-${category}`;
            // let isChecked = document.getElementById(id).checked;
            // formatted for react-bs 2.x and BS 5.1
            category_tags.push((
                <Form.Check id={id} size="sm" label={`${category}   `} inline className="qc-IE-checkboxes" type="switch" disabled={!editable} />
            ));
        });
                    // <Form.Check.Input type="checkbox" hidden />
                    // <Form.Check.Label>
                    //     <Badge id={`${id}-badge`} pill bg="secondary">{category}:{id}</Badge>
                    // </Form.Check.Label>

                // <Form.Control id={id} className="qc-IE-checkboxes" type="checkbox" disabled={!editable} />
                // <Badge pill bg="light" className="qc-IE-categories">{category}</Badge>

        // Callback function to save issue
        let saveIssue = (e) => {
            e.preventDefault();
            let issueID = e.target['qc-IE-issue-id'].value;
            let modifiedOn = e.target['qc-IE-modified-on'].value;
            let published = e.target['qc-IE-published'].checked;
            let network = e.target['qc-IE-network'].value.toUpperCase();
            let station = e.target['qc-IE-station'].value;
            let status = e.target['qc-IE-status'].value;
            let description = e.target['qc-IE-description'].value;
            let JiraTexts = e.target['qc-IE-Jira-texts'].value;
            let categories = [];

            this.state.categories.forEach((category) => {
                if (e.target[`qc-IE-category-${category.category}`].checked) {
                    categories.push(category.category);
                }
            });

            // Checks for at least one category selected
            if (categories.length === 0) {
                this.setState({ error: INV_CATEGORY });
                return;
            }
            
            // Cleans up Jira Texts
            JiraTexts = JiraTexts ? JiraTexts.split(',') : [];
            JiraTexts = JiraTexts.map(text => text.trim());
            this.setState({ loading: true });
            axios.post(NETSTAT_SAVE_ISSUE_URI, 
                { issueID, modifiedOn, published, network, station, status, description, categories, JiraTexts }, 
                { cancelToken: this.source.token, timeout: 3000, withCredentials: true })
                .then(res => {
                    this.setState({ error: '', loading: false, showIssueEditor: false });
                    this.getIssues();
                    // document.getElementById('qc-IE-modified-on').value = res.data.modifiedOn;
                    // document.getElementById('qc-IE-issue-id').value = res.data._id;
                    // this.updateIssueMetaData(res.data.createdBy, res.data.createdOn, res.data.modifiedBy, res.data.modifiedOn);

                    this.setIssueID(res.data._id, res.data.station, res.data.modifiedOn);
                })
                .catch(err => {
                    console.log(err);
                    let error = err.response ? err.response.data.error : null;
                    error = error ? error : ERR_SAVE_ISSUE;
                    if (axios.isCancel(err)) {
                        console.log('Request cancelled');
                    }
                    else {
                        this.setState({ error, loading: false, show: true });
                    }
                });

        };

        let openRemoveIssueModal = () => {
            let modalState = this.state.removeIssueModal;
            if (modalState.issueID) {
                modalState.showModal = true;
                this.setState({ modalState, error: '' });
            }
        };

        let showModal = this.state.showIssueEditor;
        let onHide = () => {
            this.setState({ showIssueEditor: false });
        };

        return (
            <Modal id="qc-IE-modal" animation={false} show={showModal} onHide={onHide} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Issue Details</Modal.Title>
                </Modal.Header>
                    <Form onSubmit={saveIssue} id="qc-netstat-issue-editor" >
                        <Modal.Body className="qc-flex-vertical">
                            <Col id="qc-IE-form-col">
                                <Row>
                                    <p className="qc-error-message">{this.state.error}</p>
                                    <section id="qc-ns-issue-metadata"></section>
                                </Row>
                                <Row>
                                    <Col sm="auto" >
                                        <Row>
                                            <InputGroup size="sm">
                                            <InputGroup.Text id="qc-IE-network-lbl"><strong>Net</strong></InputGroup.Text>
                                            <Form.Control id="qc-IE-network" size="sm" type="text" aria-label="Small" aria-describedby="qc-IE-network-lbl" maxLength="2" length="2" autoComplete="off"
                                                defaultValue={this.state.defaultNet} required disabled={!editable} />
                                            </InputGroup>
                                        </Row>
                                    </Col>

                                    <Col sm="auto">
                                        <Row>
                                        <InputGroup size="sm">
                                            <InputGroup.Text id="qc-IE-station-lbl"><strong>Station</strong></InputGroup.Text>
                                            <Form.Control id="qc-IE-station" size="sm" as="select" aria-label="Small" aria-describedby="qc-IE-station-lbl" className="form-select" required disabled={!editable} >
                                                {stationlist_options}
                                            </Form.Control>
                                        </InputGroup>
                                        </Row>
                                    </Col>

                                    <Col sm="auto">
                                        <Row>
                                        <InputGroup size="sm">
                                            <InputGroup.Text id="qc-IE-status-lbl"><strong>Status</strong></InputGroup.Text>
                                            <Form.Control id="qc-IE-status" size="sm" as="select" aria-label="Small" aria-describedby="qc-IE-status-lbl" className="form-select" required disabled={!editable}>
                                                {statuslist_options}
                                            </Form.Control>
                                        </InputGroup>
                                        </Row>
                                    </Col>
                                </Row>
                            <Row className="align-items-center qc-IE-editform-row">

                                <Col xs={9}>
                                    <Row>
                                        <InputGroup size="sm">
                                            <InputGroup.Text id="qc-IE-Jira-texts-lbl"><strong>JIRA Tickets</strong></InputGroup.Text>
                                            <Form.Control id="qc-IE-Jira-texts" sm={8} size="sm" type="text" length="10" aria-describedby="qc-IE-Jira-texts-lbl" 
                                                placeholder="123, 456, ..." autoComplete="off" disabled={!editable} />
                                        </InputGroup>
                                    </Row>
                                </Col>

                                <Col xs={3}>
                                    <Row>
                                        <Form.Check size="sm" id="qc-IE-published" label="Published" inline className="qc-IE-published" type="switch" disabled={!editable} />
                                    </Row>
                                </Col>
                                </Row>
                            <Row className="qc-IE-editform-row">

                                    <Form.Group controlId="qc-IE-issue-id">
                                        <Form.Control disabled={!editable} type="text" hidden readOnly />
                                    </Form.Group>

                                    <Form.Group controlId="qc-IE-modified-on">
                                        <Form.Control disabled={!editable} type="text" hidden readOnly />
                                    </Form.Group>
                                </Row>
                                <Form.Group as={Row} controlId="qc-IE-description">
                                    <Form.Control disabled={!editable} size="sm" as="textarea" rows="4" placeholder="Description..." required />
                                </Form.Group>
                            <Row className="qc-IE-editform-row">
                                <Form.Group align='center' controlId="qc-IE-cat-tags-row">
                                    {category_tags}
                                </Form.Group>
                                </Row>
                            </Col>
                </Modal.Body>
                <Modal.Footer>
                                <ButtonGroup id="qc-IE-buttons">
                                    { editable ?
                                        <Form.Group variant="none" as={Button} controlId="qc-IE-save-btn">
                                                <Form.Control type="submit" value={loading ? '...' : 'Save'} disabled={loading || !editable} />
                                        </Form.Group> : null
                                    }
                                    {this.state.removeIssueModal.issueID && editable ?
                                        <Form.Group variant="none" as={Button} controlId="qc-IE-remove-btn">
                                            <Form.Control onClick={openRemoveIssueModal} type="button" value={loading ? '...' : 'Remove'} disabled={loading || !editable} />
                                        </Form.Group> : null}
                                </ButtonGroup>
                </Modal.Footer>
                </Form>
            </Modal>
        );
    }

    // Callback function to clear Issue Editor
    clearIssueEditor() {
        let issueIDNode = document.getElementById("qc-IE-issue-id");
        let modifiedOnNode = document.getElementById("qc-IE-modified-on");
        let publishNode = document.getElementById("qc-IE-published");
        let networkNode = document.getElementById("qc-IE-network");
        let stationNode = document.getElementById("qc-IE-station");
        let statusNode = document.getElementById("qc-IE-status");
        let descriptionNode = document.getElementById("qc-IE-description");
        let JiraTextNode = document.getElementById("qc-IE-Jira-texts");
        let issueCreatedModifiedNode = document.getElementById("qc-ns-issue-metadata");

        issueIDNode.value = '';
        modifiedOnNode.value = '';
        publishNode.checked = false;
        networkNode.value = this.state.defaultNet;
        stationNode.value = this.state.stations[0];
        statusNode.value = this.state.statuses[0];
        descriptionNode.value = '';
        JiraTextNode.value = '';

        issueCreatedModifiedNode.innerHTML = null;

        this.state.categories.forEach((category) => {
            let cat_id = `qc-IE-category-${category.category}`;
            document.getElementById(cat_id).checked = false;
        });

        this.setState({ error: '' });
        this.setIssueID('', '', '');
    }

    generateRemoveIssueModal() {
        let modifiedOn = this.state.removeIssueModal.modifiedOn;
        let issueID = this.state.removeIssueModal.issueID;
        let issueCode = this.state.removeIssueModal.issueCode;
        let showModal = this.state.removeIssueModal.showModal;
        let userInput = this.state.removeIssueModal.userInput;
        userInput = userInput ? userInput : '';

        let onHide = () => {
            let modalStates = this.state.removeIssueModal;
            modalStates.showModal = false;
            this.setState({ modalStates });
        };
        let onInput = (e) => {  
            let newState = this.state.removeIssueModal;
            newState['userInput'] = e.target.value;   
            this.setState({ removeIssueModal: newState });
        };
        let removeIssue = () => {
            this.setState({ loading: true, showIssueEditor: false, removeIssueModal: {} });
            axios.post(NETSTAT_REMOVE_ISSUE, 
                { issueID, modifiedOn },
                { cancelToken: this.source.token, timeout: 2500, withCredentials: true })
                .then(res => {
                    console.log('success');
                    this.getIssues();
                    let stateObj = { 
                        loading: false,  
                        error: ''
                    };
                    this.setState(stateObj);
                })
                .catch(err => {
                    if (axios.isCancel(err)) {
                        console.log('Request cancelled');
                    }
                    else {
                        let error = err.response ? err.response.data : null;
                        error = error ? error.error : ERR_SAVE_ISSUE;
                        let stateObj = { 
                            loading: false,
                            error: error
                        };
                        this.setState(stateObj);
                        console.log(error);
                    }
                });
        };
        let onSubmit = (e) => {
            e.preventDefault();
            if (userInput === issueCode) {
                removeIssue();
            }
        } ;
        
        let remove_issue_btn = userInput === issueCode ? (
            <button type="submit">
            I know what I'm doing. Delete issue.
            </button>
            ) : (
            <button type="button" disabled>
            I know what I'm doing. Delete issue.
            </button>
        );
        
        return (
            <Modal id="qc-remove-item-modal" animation={false} show={showModal} onHide={onHide} centered>
            <Modal.Header className="qc-remove-item-modal-header" closeButton>
            Delete issue?
            </Modal.Header>
            <Modal.Body className="qc-flex-vertical">
            <p>
            This action <b>cannot</b> be undone.<br/>
            This will permanently delete issue <strong>{issueCode}</strong>.
            <br/><br/>Type <strong>{issueCode}</strong> below to confirm.
            </p>
            <form className="qc-confirm-remove-item qc-flex-vertical"
                onSubmit={onSubmit}>
            <input type="text" value={userInput} onChange={onInput}/>
            {remove_issue_btn}
            </form>
            </Modal.Body>
            </Modal>
        );
    }

    updateIssueMetaData(createdBy, createdOn, modifiedBy, modifiedOn) {
        let issueMetaData = document.getElementById("qc-ns-issue-metadata");
        const moment = require('moment');
        const momentFormat = "YYYY-MM-DD";
        let createdDate = moment(createdOn).format(momentFormat); 
        let modifiedDate = moment(modifiedOn).format(momentFormat); 

        issueMetaData.innerHTML = `
            <p><small>
                Created By: ${createdBy}<br/>
                Created On: ${createdDate}
            </small></p>
            <p><small>
                Modified By: ${modifiedBy}<br/>
                Modified On: ${modifiedDate}
            </small></p>
        `;
    }

    populateIssueEditor(row) {
        // Open issue editor
        this.setState({ showIssueEditor: true, error: '' });

        //issue_editor.
        let _id = row.cells[0].data[1]._id;
        let modifiedOn = row.cells[0].data[1].modifiedOn;
        let modifiedBy = row.cells[0].data[1].modifiedBy;
        let createdOn = row.cells[0].data[1].createdOn;
        let createdBy = row.cells[0].data[1].createdBy;
        let published = row.cells[0].data[1].published;
        let network = row.cells[0].data[0];
        let station = row.cells[1].data;
        let status = row.cells[2].data;
        let description = row.cells[3].data;
        let categories = row.cells[4].data;
        let JiraTexts = row.cells[5].data;

        let issueIDNode = document.getElementById("qc-IE-issue-id");
        let modifiedOnNode = document.getElementById("qc-IE-modified-on");
        let publishNode = document.getElementById("qc-IE-published");
        let networkNode = document.getElementById("qc-IE-network");
        let stationNode = document.getElementById("qc-IE-station");
        let statusNode = document.getElementById("qc-IE-status");
        let descriptionNode = document.getElementById("qc-IE-description");
        let JiraTextNode = document.getElementById("qc-IE-Jira-texts");

        issueIDNode.value = _id;
        modifiedOnNode.value = modifiedOn;
        publishNode.checked = published;
        networkNode.value = network;
        stationNode.value = station;
        statusNode.value = status;
        descriptionNode.value = description;
        JiraTextNode.value = JiraTexts.texts;


        this.updateIssueMetaData(createdBy, createdOn, modifiedBy, modifiedOn);

        this.state.categories.forEach((category) => {
            let cat_id = `qc-IE-category-${category.category}`;
            document.getElementById(cat_id).checked = false;
        });
        categories.categories.split(',').forEach((category) => {
            let cat_id = `qc-IE-category-${category}`;
            document.getElementById(cat_id).checked = true;
        });
        // document.getElementById("category-choices").value = categories.categories.split(',');



        let issueID = issueIDNode.value;
        this.setIssueID(issueID, station, modifiedOn);
    }

    setIssueID(issueID, station, modifiedOn) {
        let issueCode = station + issueID.substr(issueID.length - 5);
        let removeIssueModal = { issueCode, issueID, modifiedOn };
        this.setState({ removeIssueModal });
    }

    async addNewIssue() {
        await this.setState({ showIssueEditor: true });
        this.clearIssueEditor();
    }

    render() {
        if (this.state.categories && this.state.issueHeaders && 
            this.state.categories.length > 0 && this.state.stations.length > 0 &&
            this.state.statuses.length > 0 && this.state.issueHeaders.length > 0) {
            return (
                <div className="qc-flex-vertical" id="qc-IE-content">
                <Row id="qc-IE-info" >
                <Col>
                <p>
                    Use the following codes below to construct a query for a specific column.<br/> 
                    For multiple items of a column, separate each item by a <b>,</b><br/>
                    To use multiple codes, separate each code by a <b>&amp;</b><br/>
                </p>    
                <ul>
                    <li><b>Published</b> p: [true/false]</li>
                    <li><b>Network</b> n: [item]</li>
                    <li><b>Station</b> sta: [item]</li>
                    <li><b>Status</b> stat: [item]</li>
                    <li><b>Category</b> c: [item]</li>
                </ul>
                <p>
                    <i>ex) c: ee &amp; sta: aak, bork </i> <br/>
                    The above queries stations AAK and BORK with Equipment Exceptions issues. 
                </p>
                </Col>
                <Col>
                    <ul>
                        <li><b>EE</b> - Equipment Exceptions</li>
                        <li><b>DL</b> - Data Loss</li>
                        <li><b>TI</b> - Telemetry Issues</li>
                        <li><b>DQ</b> - Data Quality</li>
                        <li><b>NS</b> - Non-Seismic Channels</li>
                        <li><b>CC</b> - Calibrations Completed</li>
                        <li><b>CS</b> - Calibration Scheduled</li>
                        <li><b>MR</b> - Mass Recenters</li>
                    </ul>
                </Col>
                </Row>
                <div id="qc-IE-grid" ref={this.grid_ref}></div>
                {this.generateRemoveIssueModal()}
                {this.state.stations && this.state.statuses ? 
                    this.generateIssueEditor() : null}
                <div id="qc-IE-issue-btm-btn">
                {
                    this.state.qcnetstatissues &&
                    <button id="qc-IE-add-btn" onClick={this.addNewIssue.bind(this)} value="save"><FaPlusSquare id="qc-IE-add-icon" /></button>
                }
                {this.generateCSVDownload()}
                </div>
                </div>
            );
        }
        return (
            <div className="qc-flex-vertical" id="qc-IE-content">
                <p className="qc-error-message">{ERR_STATIONS}</p>
            </div>
        );
    }
}

export default IssueManager;
