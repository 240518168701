/** 
 * Author: Robert Hernandez
 * Last Modified: June 19, 2020
 * 
 * Side bar component of the qc metrics page.
 * Used to add tabs to the left side of the QC Metric page to display metrics
 * of the station selected.
 */

import React from 'react';
import {Nav} from 'react-bootstrap';

/** A list of stations must be passed as a prop to this custom tag */
class SideBar extends React.Component {

    /**
     * Generates a tablist of stations to act as a sidebar.
     * @param {Array} props 
     */
    tabList(stations) {
        // A tab for the sidebar is created for each station
        let tabs = stations.map((station, index) => {
            return (
                <Nav.Item key={`qc-sidebar-tab-${index}`}>
                <Nav.Link eventKey={station}>{station}</Nav.Link>
                </Nav.Item>
            );
        });
        return (
            <Nav id="qc-qcmetric-sidebar" variant="pills">
            {tabs}
            </Nav>
        );
    }

    render() {
        return this.tabList(this.props.stations);
    }
}

export default SideBar;