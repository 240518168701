/**
 * Author: Robert Hernandez
 * Last Modified: June 19, 2020
 * 
 * QC Metrics Page
 * Organizes the percent availability, num gaps, max gap, and noise spectrograms
 * for each active station.
 */

import React from 'react';
import axios from 'axios';
import ReactToPrint from 'react-to-print';
import { Tab, Tabs } from 'react-bootstrap';
import IssueManager from './IssueManager';
import NetStatReport from './NetStatReport';
import { AiFillPrinter } from 'react-icons/ai'


class QCNetStat extends React.Component {

    /** 
     * Source is a cancel token that is passed to axios calls to cancel listening
     * for responses from the api. This is vital to prevent axios from continuing
     * to make component changes after the component has already been unmounted,
     * which would cause potential memory leaks.  
     */
    CancelToken = axios.CancelToken;
    source = this.CancelToken.source();

    /**
     * Cancels any asynchronous calls before leaving page to prevent memory leaks.
     */
    componentWillUnmount() {
        
        this.source.cancel();
    } 

    render() {
        return (
            <main id="qc-net-stat" className="qc-flex-vertical">
            <Tabs defaultActiveKey="Report" transition={false} mountOnEnter={true} unmountOnExit={true}>
            <Tab eventKey="Report" title="Report">
            <div id="qc-ns-report-tab" className="qc-flex-vertical">
                <NetStatReport ref={el => (this.printRef = el)} />
                <ReactToPrint 
                    trigger={() => {
                        return (
                            <AiFillPrinter id="qc-ns-print-icon" />
                        );
                    }} 
                    content={() => this.printRef}
                />
            </div>
            </Tab>
            <Tab eventKey="Issues" title="Issues">
                <IssueManager />
            </Tab>
            </Tabs>
            </main>
            );
    }
}

export default QCNetStat;
