import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import Login from './Pages/Login';
import checkAuth from './Pages/Common/checkAuth';
import QCMetrics from './Pages/QCMetrics/QCMetrics';
import QCNetStat from './Pages/QCNetStat/QCNetStat';
import Account from './Pages/Account'; 
import { QCNOTE_URI, QCNETSTAT_URI, AUTH_VERIFY, HOME_URI } from './Constants/Const_URI';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Header from './Pages/Common/Header';

class App extends React.Component {
    render() {
        let default_redirect = (<Redirect to={{ pathname: '/qcmetrics' }} />);
        console.log(`qcweb.app home-uri: ${HOME_URI}`);
        return (
            <Router>
                <Header />
                <Switch>
                    <Route path="/qcmetrics" exact component={checkAuth(QCMetrics, QCNOTE_URI)} />
                    <Route path="/qcnetstat" exact component={checkAuth(QCNetStat, QCNETSTAT_URI)} />
                    <Route path="/account" exact component={checkAuth(Account, AUTH_VERIFY)} />
                    <Route path="/login" exact component={Login} />
                    <Route path='/' component={checkAuth(default_redirect, HOME_URI)} />
                </Switch>
            </Router>
        );
    }
};

export default App;
