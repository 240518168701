/**
 * Author: Robert Hernandez
 * Last Modified: June 19, 2020
 * 
 * QC Metrics Page
 * Organizes the percent availability, num gaps, max gap, and noise spectrograms
 * for each active station.
 */

import React from 'react';
import axios from 'axios';
import { QCNETSTAT_URI, NETSTAT_PUBLISHED_URI } from '../../Constants/Const_URI';
import { ERR_GET_ISSUES } from '../../Constants/Const_ERR';
import { Table } from 'react-bootstrap';

class NetStatReport extends React.Component {
    constructor(props) {
        super(props);
        this.grid_ref= React.createRef();
        this.grid = null;
        this.state = {
            reportObj: {},
            catgories: [],
            issues: [],
            loading: false,
            error: '',
        }
    }

    /** 
     * Source is a cancel token that is passed to axios calls to cancel listening
     * for responses from the api. This is vital to prevent axios from continuing
     * to make component changes after the component has already been unmounted,
     * which would cause potential memory leaks.  
     */
    CancelToken = axios.CancelToken;
    source = this.CancelToken.source();

    /**
     * Cancels any asynchronous calls before leaving page to prevent memory leaks.
     */
    componentWillUnmount() {
        this.source.cancel();
    } 

    componentDidMount() {
        this.NetStatSetup();
    }

    async NetStatSetup() {
        this.setState({ loading: true, error: '' });
        await this.getNetStatConstants();
        await this.getIssues();
        this.setState({ loading: false });
    }

    getNetStatConstants() {
        return axios.post(QCNETSTAT_URI, { }, 
            { cancelToken: this.source.token, timeout: 2500, withCredentials: true })
            .then(res => {
                this.setState({
                    categories: res.data.categories,
                });
            })
            .catch(error => {
                if (axios.isCancel(error)) {
                    console.log('Request cancelled');
                }
                else {
                    console.log(error);
                }
            });
    }


    getIssues() {
        return axios.post(NETSTAT_PUBLISHED_URI, {  },
            { cancelToken: this.source.token, timeout: 3000, withCredentials: true })
            .then(res => {
                let issues = res.data.issues;
                this.setState({ issues });
                return issues;
            })
            .then((issues) => {
                let reportObj = {};
                issues.forEach((issue) => {
                    issue.categories.forEach((category) => {
                        if (reportObj[category.category] === undefined) {
                            reportObj[category.category] = [];
                        }
                        reportObj[category.category].push(issue);
                    })

                });
                this.setState({ reportObj });
            })
            .catch(err => {
                if (axios.isCancel(err)) {
                    console.log('Request cancelled');
                }
                else {
                    let error = err.response ? err.response.data : null;
                    error = error ? error.error : err.message;
                    error = error ? error : ERR_GET_ISSUES;
                    let stateObj = { 
                        error: error
                    };
                    this.setState(stateObj);
                    console.log(error);
                }
            });
    }

    generateCategoryTable() {
        let reportObj = this.state.reportObj;
        let published_categories = Object.keys(reportObj);

        // Sort report categories based on sequence in state.categories
        let ordered_categories = this.state.categories.map((catObj) => { return catObj.category }).filter(cat => published_categories.includes(cat));
        console.log("ordered_categories", ordered_categories);
        let tables = ordered_categories.map((category) => {
            let issues = reportObj[category];
            let data = [];
            issues.forEach((issue) => {
                let JiraTexts = [];
                issue.JiraTickets.forEach((tic) => {
                    JiraTexts.push((
                        <a key={`Jira-Url-${tic.url}`} target="_blank" rel="noopener noreferrer" href={tic.url}>
                            ({tic.text})
                        </a>
                    ));
                });
                data.push((
                    <tr key={`${category}-issue-${issue._id}`}>
                    <td className="qc-ns-report-network">{issue.network}</td>
                    <td className="qc-ns-report-station_status"><p>{issue.station}</p><p>{issue.status}</p></td>
                    <td className="qc-ns-report-description">{issue.description}</td>
                    <td className="qc-ns-report-jira">{JiraTexts}</td>
                    <td className="qc-ns-report-created_on">{issue.CreatedOn}</td>
                    </tr>
                ));
            });
            let table =  (
                <Table key={`${category}-table`} hover >
                <thead>
                    <tr>
                    <th colSpan="4" >{category}</th>
                    </tr>
                </thead>
                <tbody>
                    {data}
                </tbody>
                </Table>
            );
            return table; 
        });
        return (<div id="qc-ns-report-table">{tables}</div>);
    }

    render() {
        if (this.state.categories && this.state.issues.length) {
            let dateOpts = { 
                weekday: 'short', 
                year: 'numeric',
                month: 'short',
                day: 'numeric',
                timezone: 'America/Los_Angeles',
                hour: '2-digit',
                minute: '2-digit'
            };
            let dateStr = new Date().toLocaleString('en-US', dateOpts)
            return (
                <div id="qc-ns-report-content" className="qc-flex-vertical">
                <h2 id="qc-ns-report-header">II Network Status Report</h2>
                <h3 id="qc-ns-report-header">{dateStr}</h3>
                {this.generateCategoryTable()}
                </div>
            );
        }
        else if (this.state.categories) {
            return (
                <div id="qc-ns-report-content" className="qc-flex-vertical">
                No issues have been published yet...
                </div>
            )
        }
        return null;
    }
}

export default NetStatReport;
