/**
 * Author: Robert Hernandez
 * Last Modified: June 18, 2020
 * 
 * Login page for IDA QC Web App.
 */

import React from 'react';
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import { AUTH_LOGIN_URI } from '../Constants/Const_URI';
import { ERR_LOGIN } from '../Constants/Const_ERR';

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            error: '',
            loading: false, // Set to true when validating credentials
            redirect: false // Set to true when credentials inputted are valid
        };
    }

    /** 
     * Source is a cancel token that is passed to axios calls to cancel listening
     * for responses from the api. This is vital to prevent axios from continuing
     * to make component changes after the component has already been unmounted,
     * which would cause potential memory leaks.  
     */
    CancelToken = axios.CancelToken;
    source = this.CancelToken.source();

    componentDidMount() {
        this.source = this.CancelToken.source();
        
        // Can be swapped with optional chaining in ES2020
        const error = this.props.location 
        ? (this.props.location.state ? this.props.location.state.error : null) 
        : null;
        
        // No error either means that the user has a valid JWT and is redirected to
        //   the home page; or the user does not have a token or valid token and
        //   after redirection to the homepage, the homepage will redirect to the
        //   Login page with an error msg in the props.  
        if (!error) {
            this.setState({ redirect: true });
        }
        else if ((error !== 'INV_TOKEN') && (error !== 'NO_TOKEN')) {
            this.setState({ error });
        }
        
        // Resets the history's error state so that it does not reuse an old
        //   error message
        // EX: Server failure causes an error message. Once the server is fixed and the
        //     page is refreshed, the error message will remains in history and will be
        //     displayed. By resetting the error state, the page will fetch for a new
        //     error state. 
        this.props.history.replace('/login', { error: null });
    }
    
    /**
     * Cancels any asynchronous calls before leaving page to prevent memory leaks.
     */
    componentWillUnmount() {
        this.source.cancel();
    } 
    
    /**
     * Handles dynamic input from the user for input tags
     */
    handleInputChange = (event) => {
        const { value, name } = event.target;
        this.setState({
            [name]: value
        });
    };
    
    /**
     * Requests the server to validate the inputted credentials.
     */
    onSubmit = (event) => {
        event.preventDefault();
        this.setState({ error: '', loading: true });

        axios.post( AUTH_LOGIN_URI ,
            { username: this.state.username, password: this.state.password },
            {
                timeout: 5000, withCredentials: true, cancelToken: this.source.token, 
            })
            .then(res => {
                // Response status indicates valid credentials; routes to home page.
                this.props.history.push('/');
            })
            .catch(err => {
                let error = err.response ? err.response.data : null;
                error = error ? error.error : ERR_LOGIN;
                this.setState({ error, loading: false });
            });
        };
        
    render() {

        let { redirect, loading, error } = this.state;
        const loadingMessage = "Logging in...";

        if (redirect) {
            return <Redirect to={{ pathname: '/'}}/> 
        }
        
        return (
            <div className="qc-login-flex-vertical qc-login-frame">
            <main id="qc-login-content" className="qc-login-flex-vertical qc-login-frame-content">
            <h1>IDA QC</h1>
            <h2>{error}</h2>
            { loading ?  <h2>{loadingMessage}</h2> :
            <form onSubmit={this.onSubmit.bind(this)} 
                className="qc-login-flex-vertical qc-login-form" >
                <input type="text" name="username" placeholder="Enter username"
                    value={this.state.username} onChange={this.handleInputChange.bind(this)} 
                    required />
                <input type="password" name="password" placeholder="Enter password"
                    value={this.state.password} onChange={this.handleInputChange.bind(this)}
                    required />
                <input type="submit" value="Login" id="qc-login-btn" />
            </form>
            }
            </main>
            </div>
        );
    }
}

export default Login;