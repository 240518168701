/**
 * Author: Robert Hernandez
 * Last Modified: AUG 13th, 2020
 * 
 * This file contains all error responses for qcweb. 
 */

export const ERR_LOGIN = 'Server failure. Unable to login.';
export const ERR_NO_NOTE = 'No note available...';
export const ERR_UNKNOWN = 'Unknown error. Try to log in again.';
export const ERR_SERVER = 'Server failure. Denied Access';
export const ERR_SAVE_USER = 'Error. Could not save account. Refresh page.';
export const ERR_GET_USERS = 'Failed to get users.';
export const ERR_GET_ISSUES = 'Failed to get NetStat issues.';
export const ERR_STATIONS = 'Failed to get list of stations.';
export const ERR_UNMATCH_PWD = 'Password does not match.';
export const ERR_LOGOUT = 'Server Error. Refresh page...';
export const ERR_SAVE_ISSUE = 'Error. Could not save issue. Refresh page.';

export const INV_CATEGORY = 'Error: At least one category must be selected.';