import React from 'react';
import axios from 'axios';
import { AUTH_LOGOUT_URI, AUTH_VERIFY } from '../../Constants/Const_URI';
import { ERR_LOGOUT, ERR_SERVER } from '../../Constants/Const_ERR';
import { FaUserCog } from "react-icons/fa";
// import { NavLink, useNavigate } from 'react-router-dom';
import { NavLink, withRouter } from 'react-router-dom';
import { Popover, OverlayTrigger } from 'react-bootstrap';

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            header_on: false,
            username: ''
        };
    }

    componentDidMount() {
        this.getUsername();
    }
    
    componentDidUpdate(prevProps) {
        if (this.props.location.pathname === '/login' || this.props.location.pathname === '/') {
            if (this.state.header_on) {
                this.setState({ header_on: false, username: '' });
            }
        }
        else if (!this.state.header_on) {
            this.setState({ header_on: true } );
            this.getUsername();
        }
    }
    
    /** 
     * Source is a cancel token that is passed to axios calls to cancel listening
     * for responses from the api. This is vital to prevent axios from continuing
     * to make component changes after the component has already been unmounted,
     * which would cause potential memory leaks.  
     */
    CancelToken = axios.CancelToken;
    source = this.CancelToken.source();
    
    /**
     * Cancels any asynchronous calls before leaving page to prevent memory leaks.
     */
    componentWillUnmount() {
        this.source.cancel();
    } 
    
    logout() {
        axios.post(AUTH_LOGOUT_URI, {} ,{ cancelToken: this.source.token, timeout: 2000, 
            withCredentials: true })
            .then(res => {
                this.props.history.push('/login');
            })
            .catch(error => {
                console.log(error);
                if (axios.isCancel(error)) {
                    console.log('Request cancelled');
                }
                else {
                    console.log(ERR_LOGOUT);
                    window.location.reload();
                }
            });
    }

        
    getUsername() {
        axios.post(AUTH_VERIFY, {} ,{ cancelToken: this.source.token, timeout: 2000, 
            withCredentials: true })
            .then(res => {
                this.setState({ username: res.data.username });
            })
            .catch(error => {
                if (axios.isCancel(error)) {
                    console.log('Request cancelled');
                }
                else {
                    console.log("getUsername: AUTH_VERIFY=",AUTH_VERIFY);
                    console.log(ERR_SERVER);
                }
            }
        );      
    }
        
    render() {
        if (!this.state.header_on) {
            return null;
        }
                
        const popover = (
            <Popover id="popover-basic">
            <Popover.Body>
                <NavLink className="qc-acc-dropdown-nav-links"  exact activeClassName="qc-header-nav-active" to="/account">
                    Account 
                </NavLink>
                <p className="qc-acc-dropdown-nav-links"  onClick={this.logout.bind(this)}>
                    Logout
                </p>
            </Popover.Body>
            </Popover>
        );

        return (
            <header className="qc-header">
            <div className="qc-header-background">
                <h1>IDA DCC QC</h1>
                <h2>PROJECT IDA</h2>
            </div>
            <nav className="qc-header-navbar">
            <NavLink exact className="qc-nav-link" isActive={(match, location) => match} 
                        activeClassName="qc-header-nav-active" to="/qcmetrics">
                QC Metrics 
            </NavLink>
            <NavLink exact className="qc-nav-link" isActive={(match, location) => match} 
                        activeClassName="qc-header-nav-active" to="/qcnetstat">
                QC NetStats 
            </NavLink>
            <ul>
                <li id="qc-acc-dropdown">
                <OverlayTrigger trigger="focus" placement="bottom" overlay={popover} >
                <button><FaUserCog id="qc-acc-icon" size="35px" /></button>
                </OverlayTrigger>
                </li>
                <li id="qc-header-username">{this.state.username}</li>
            </ul>
            </nav>
            </header>);
    }
}

export default withRouter(Header);